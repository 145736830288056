.page {
    font-family: PT Mono, monospace;
}

.page-one {
    background: #f8f8f8;
    position: relative;
    min-height: 100vh;
    min-width: 100vh;
    display: flex;
    padding-bottom: 30px;
}

.page-rate {
    background: #f8f8f8;
    position: relative;
    min-height: 100vh;
    min-width: 100vh;
    padding-bottom: 30px;
}

.page-two {
    display: flex;
    background: #fff;
    min-width: 100vh;
}

.page-three {
    display: flex;
    background: #f8f8f8;
    min-width: 100vh;
}

.footer {
    min-height: 8vh;
    background: #f8f8f8;
    border-top: 1px solid #eaeaea;
}

.footer p {
    font-size: 18px;
}

.hidden {
    display: none;
}

.justificado {
    text-align: justify;
    text-justify: inter-word;
}